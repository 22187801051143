<template>
  <div class="intro-y col-span-12">
    <div class="chat__box box min-h-1/2">
      <div class="h-full flex flex-col">
        <div
          class="flex flex-col sm:flex-row border-b border-gray-200 dark:border-dark-5 px-5 py-4"
        >
          <div class="flex items-center">
            <app-list-item-image
              :value="chatImageUrl"
              type="user"
            ></app-list-item-image>
            <div class="mx-3">
              <router-link
                class="font-medium text-base text-theme-10"
                target="_blank"
                :to="`/users/${chat.client}`"
              >
                <Tippy
                  tag="div"
                  class="flex items-center gap-2"
                  :content="i18n('user.view.clickHereToView')"
                >
                  {{ chatUserName }}
                  <external-link-icon
                    size="1x"
                    class="custom-class"
                  ></external-link-icon>
                </Tippy>
              </router-link>
              <div class="text-xs text-gray-500 truncate">
                {{ i18n('complaints.complaintId') }}: {{ chat.id }}
              </div>
              <div class="text-gray-600 text-xs sm:text-sm dark:text-gray-300">
                <!-- <app-show-date-time
                  :dateTime="chat['createdAt']"
                  date-class="notification-date"
                /> -->
              </div>
            </div>
          </div>

          <div
            class="flex items-center mt-5 sm:mt-0 pt-3 sm:pt-0 -mx-5 sm:mx-0 px-5 sm:px-0"
            :class="isRTL ? 'sm:mr-auto' : 'sm:ml-auto'"
          >
            <div
              class="dropdown"
              :class="isRTL ? 'mr-auto' : 'ml-auto'"
              v-if="chat.status == 'open'"
            >
              <button class="dropdown-toggle" aria-expanded="false">
                <MoreVerticalIcon
                  class="w-5 h-5"
                  @click="chatMenuDropDown = true"
                />
              </button>
              <div class="dropdown-menu" v-if="chatMenuDropDown">
                <div
                  class="dropdown-menu__content searchDropdown box dark:bg-dark-1 p-3"
                  id="dropdown"
                >
                  <div
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer dropdown-toggle"
                    v-if="chat.status != 'pending'"
                    aria-expanded="false"
                    @click="doCloseChat()"
                  >
                    <LockIcon
                      class="w-4 h-4"
                      :class="isRTL ? 'ml-2' : 'mr-2'"
                    />
                    {{ i18n('complaints.closeComplaint') }}
                  </div>
                  <div
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"
                    v-if="chat.status == 'closed'"
                  >
                    <UnlockIcon
                      class="w-4 h-4"
                      :class="isRTL ? 'ml-2' : 'mr-2'"
                    />
                    {{ i18n('complaints.openComplaint') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="messages-container"
          class="overflow-y-scroll px-5 pt-5 flex-1"
          style="min-height: 60vh"
          v-if="messages"
        >
          <div v-for="message in messages" :key="message">
            <div
              class="chat__box__text-box flex items-start gap-2 mb-4 float-left"
              style="direction: ltr !important"
              v-if="message.senderId == creator.id"
            >
              <app-list-item-image
                :value="chatImageUrl"
                type="user"
              ></app-list-item-image>
              <div
                class="bg-theme-32 dark:bg-dark-5 px-4 py-3 text-gray-700 dark:text-gray-300 rounded-r-md rounded-t-md"
              >
                <div
                  style="word-break: break-all"
                  v-if="message.type == 'text'"
                >
                  {{ message.message }}
                </div>
                <div v-else>
                  <img
                    :src="message.attachment.publicUrl"
                    alt=""
                    class="w-56"
                    data-action="zoom"
                  />
                </div>
                <div class="mt-1 text-xs text-gray-600 dark:text-gray-300">
                  {{ presenterTime(message, 'sentAt') }}
                </div>
              </div>
            </div>
            <div
              class="chat__box__text-box flex items-end mb-4 float-right"
              style="direction: ltr !important"
              v-else
            >
              <div
                class="bg-theme-31 px-4 py-3 text-white rounded-l-md rounded-t-md"
              >
                <div
                  style="word-break: break-all"
                  v-if="message.type == 'text'"
                >
                  {{ message.message }}
                </div>
                <div v-else>
                  <img
                    :src="message.attachment.publicUrl"
                    class="w-56"
                    data-action="zoom"
                  />
                </div>
                <div
                  class="mt-1 text-xs text-theme-33"
                  :class="isRTL ? 'text-left' : 'text-right'"
                >
                  <span class="mr-2">{{
                    presenterTime(message, 'sentAt')
                  }}</span>
                  <span v-if="message.id && !message.read">&#x2713;</span>
                  <span v-if="message.read" style="color: #6b6bff"
                    >&#x2713;&#x2713;</span
                  >
                </div>
              </div>
              <!-- <div class="w-10 h-10 hidden sm:block flex-none image-fit relative ml-5 mr-5">
                <img alt="Icewall Tailwind HTML Admin Template" class="rounded-full" :src="require(`@/assets/images/profile-1.jpg`)" />
              </div> -->
            </div>
            <div class="clear-both"></div>
          </div>
          <!-- <div class="w-16 typing-container" v-if="isTyping">
            <LoadingIcon icon="three-dots" color="gray" style="margin: 0 4px;" />
          </div> -->
          <div
            class="flex items-start gap-2 mb-4 float-left"
            style="direction: ltr !important"
            v-if="isTyping"
          >
            <app-list-item-image
              :value="chatImageUrl"
              type="user"
            ></app-list-item-image>
            <div class="chat-bubble">
              <div class="typing">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </div>
          </div>
        </div>
        <app-empty-page
          v-else
          :label="i18n('complaints.noMessages')"
          label-class="text-xl font-medium"
          style="min-height: 60vh"
        ></app-empty-page>
        <div class="relative">
          <!-- <app-saved-replies
            :show="show"
            :input="input"
            @select="(message) => input = message"
            @close="show = false"
          ></app-saved-replies> -->
          <div
            class="pt-4 pb-10 sm:py-4 border-t border-gray-200 dark:border-dark-5"
            v-if="chat.status != 'closed'"
          >
            <textarea
              v-if="!image"
              v-model="input"
              :placeholder="i18n('complaints.messagePlaceholder')"
              rows="2"
              class="chat__box__input form-control dark:bg-dark-3 resize-none border-transparent px-5 py-3 shadow-none focus:ring-0"
              @keydown.enter.exact.prevent="sendMessage"
              @keydown.enter.shift.exact.prevent="input += '\n'"
              @keyup.stop.prevent="onStartTyping($event)"
            ></textarea>
            <div v-else class="flex ml-auto">
              <div class="">
                <img
                  style="height: 100px"
                  class="mr-10"
                  :src="imageUrl"
                  alt=""
                  id="image-preview"
                />
              </div>
            </div>
            <div
              class="flex items-center justify-end gap-3 absolute sm:static bottom-0 ml-5 sm:ml-0 mb-5 sm:mb-0"
            >
              <!-- <button @click="show = !show">
                <Tippy tag="div" class="cursor-pointer" :content="i18n('Saved Replies')">
                  <message-square-icon size="1.75x" class="w-4 h-4 sm:w-5 sm:h-5"></message-square-icon>
                </Tippy>
              </button> -->
              <!-- <Emojis /> -->
              <div class="w-4 h-4 sm:w-5 sm:h-5 relative text-gray-600">
                <Tippy
                  tag="div"
                  class="cursor-pointer"
                  :content="i18n('Files')"
                >
                  <PaperclipIcon class="w-full h-full cursor-pointer" />
                  <input
                    type="file"
                    id="attachments"
                    class="w-full h-full top-0 left-0 absolute opacity-0 cursor-pointer"
                    accept="image/png, image/jpeg, image/jpg"
                    @input="emitEvent($event)"
                  />
                </Tippy>
              </div>
              <button
                class="w-8 h-8 sm:w-9 sm:h-9 block bg-theme-31 text-white rounded-full flex-none flex items-center justify-center"
                :class="isRTL ? 'ml-5' : 'mr-5'"
                :disabled="!input.length && !imageUrl.length"
                @click="sendMessage()"
              >
                <SendIcon class="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Emojis from '@/views/complaint/emojis.vue'
// import SavedReplies from '@/views/complaint/saved-replies.vue'
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
import moment from 'moment'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { FileUploader } from '@/shared/uploader/file-uploader'
import FirebaseRepository from '@/shared/firebase/firebase-repository'

export default defineComponent({
  name: 'app-chat-content',
  props: {
    maintenanceId: {
      type: String
    }
  },
  components: {
    // [SavedReplies.name]: SavedReplies,
    // Emojis
  },
  data() {
    return {
      show: false,
      typingEvent: null
    }
  },
  async mounted() {
    const maintenanceId = this.maintenanceId
    await this.fetchChat({ maintenanceId })
    await this.fetchMessages({ maintenanceId })
    await this.fetchMessageSender()
    const client = await FirebaseRepository.findDocument(
      'clients',
      this.chat.clientId
    )
    this.chat.client = client || this.chat.clientId
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      currentUser: 'auth/currentUser',
      userTyping: 'maintenance/chat/isTyping',
      messages: 'maintenance/chat/messages',
      chat: 'maintenance/chat/chat'
    }),
    isTyping() {
      const isTyping = this.userTyping
      if (isTyping) this.scrollBottom()
      return isTyping
    },
    creator() {
      return this.chat.client || { id: this.chat.client }
    },
    chatImageUrl() {
      return this.chat && this.chat.client && this.chat.client.avatar
        ? this.chat.client.avatar
        : null
    },
    chatUserName() {
      return this.chat.client && this.chat.client.fullName
        ? this.chat.client.fullName
        : this.i18n('common.elGhoneimyClient')
    }
  },
  setup() {
    const input = ref('')
    const image = ref(null)
    const imageUrl = ref('')
    const chatMenuDropDown = ref(false)

    return {
      image,
      imageUrl,
      input,
      chatMenuDropDown
    }
  },
  methods: {
    ...mapActions({
      fetchChat: 'maintenance/chat/fetchChat',
      doSendMessage: 'maintenance/chat/doSendMessage',
      fetchMessages: 'maintenance/chat/fetchMessages',
      fetchMessageSender: 'maintenance/chat/fetchMessageSender',
      closeChat: 'maintenance/chat/closeChat'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    formatDate(date) {
      return moment.unix(date.seconds).format('DD/MM/YYYY hh:mm A')
    },
    formatTime(date) {
      return moment.unix(date.seconds).format('hh:mm A')
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE} ريال` : `${PRICE} SAR`
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options
      const val = options.filter((el) => el.id == row[fieldName])
      return val.length ? val[0].label : 'ـــ'
    },
    scrollBottom() {
      setTimeout(() => {
        var container = document.querySelector('#messages-container')
        if (container) {
          container.scrollTop = container.scrollHeight
          this.textMessage = null
        }
      }, 10)
    },
    emitEvent(event) {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = (e) => {
        const image = {
          name: event.target.files[0].name,
          size: event.target.files[0].size,
          lastModifiedDate: event.target.files[0].lastModifiedDate,
          base64: reader.result
        }
        this.image = image
        const file = event.target.files[0]
        this.imageUrl = URL.createObjectURL(file)
      }
    },
    doCloseChat() {
      this.closeChat({ maintenanceId: this.maintenanceId })
      this.chatMenuDropDown = false
      document.getElementById('dropdown').style.display = 'none'
    },
    async sendMessage() {
      let type
      let uploads
      if (this.image) {
        type = 'image'
      } else {
        if (this.input.length == 0) return
        type = 'text'
      }

      const message = {
        message: this.input,
        attachment: { publicUrl: this.imageUrl },
        type,
        read: false,
        sentAt: new Date(),
        senderId: this.currentUser.id,
        maintenanceId: this.maintenanceId
      }

      this.messages.push(message)
      this.scrollBottom()
      if (message.type == 'image') {
        const attachment = document.getElementById('attachments').files[0]
        const path = `maintenance/${this.maintenanceId}/${this.chat.id}`
        uploads = await FileUploader.upload(path, attachment)
      }
      const notification = {
        image: uploads ? uploads.publicUrl : null,
        title: {
          en: 'Reply to chat',
          ar: 'الرد على الدردشه'
        },
        body: {
          en: message.message,
          ar: message.message
        },
        data: {
          key: 'maintenanceId',
          value: this.maintenanceId
        }
      }
      this.doSendMessage({
        ...message,
        attachment: uploads,
        notification,
        userId: this.chat.client?.id || this.chat.client
      })
      this.uploads = {}
      this.image = null
      this.input = ''
    },
    onStartTyping() {
      const DB = getFirestore()
      if (this.typingEvent) {
        clearTimeout(this.typingEvent)
        this.typingEvent = null
      } else {
        updateDoc(
          doc(DB, `maintenance/${this.maintenanceId}/chats/${this.chat.id}`),
          {
            adminTyping: true
          }
        )
      }

      this.typingEvent = setTimeout(() => {
        updateDoc(
          doc(DB, `maintenance/${this.maintenanceId}/chats/${this.chat.id}`),
          {
            adminTyping: false
          }
        )
        clearTimeout(this.typingEvent)
        this.typingEvent = null
      }, 800)
    }
  }
})
</script>
<style lang="scss">
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: rgb(8, 6, 6) !important;
    opacity: 1;
    /* Firefox */
  }
}
.isNew {
  background: red !important;
  width: 20px;
  position: absolute;
}
.chat .chat__chat-list {
  height: unset !important;
}
.no-border {
  border: unset !important;
}
.typing-container {
  background: #e9e9e9;
  padding: 0px 19px;
  border-radius: 24px;
  margin-top: 20px;
}
</style>
<style lang="scss" scoped>
.chat-bubble {
  // background-color:#E6F8F1;
  background-color: rgba(252, 236, 236, 1);
  padding: 16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}
.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  // background-color: #6CAD96 ; //rgba(20,105,69,.7);
  // background-color: rgba(252, 236, 236, 1);
  background-color: rgba(55, 65, 81, 0.7);
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    // background-color:#6CAD96; // rgba(20,105,69,.7);
    background-color: rgba(55, 65, 81, 0.7);
  }
  28% {
    transform: translateY(-7px);
    // background-color:#9ECAB9; //rgba(20,105,69,.4);
    background-color: rgba(55, 65, 81, 0.4);
  }
  44% {
    transform: translateY(0px);
    // background-color: #B5D9CB; //rgba(20,105,69,.2);
    background-color: rgba(55, 65, 81, 0.2);
  }
}
</style>
